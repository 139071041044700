import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white p-4 mt-10 text-center">
      <p>&copy; 2024 gigaGallery. All rights reserved.</p>
      <p>Check out <a href="https://www.linkedin.com/in/danielmulcahy123" className="text-blue-400">LinkedIn</a> </p>
    </footer>
  );
};

export default Footer;
